import { mergeWith } from 'lodash';
import { RoleUniqueNames } from '@melio/platform-api';

import { CapOneIcons } from '@/cl/icons/capone';
import { CloverIcons } from '@/cl/icons/clover';
import { FiservIcons } from '@/cl/icons/fiserv';
import { MelioIcons } from '@/cl/icons/melio';
import { UsBankIcons } from '@/cl/icons/usbank';
import { capOneTheme, citizensTheme, cloverTheme, fiservTheme, melioTheme, usBankTheme } from '@/cl/theme/partners';
import { gustoIcons, gustoTheme } from '@/cl/theme/partners/gusto';
import { OptumBankIcons, optumBankTheme } from '@/cl/theme/partners/optum-bank';
import { PayPalIcons, payPalTheme } from '@/cl/theme/partners/paypal';
import { defaultConfig } from '@/configByPartner/default.config';
import { fiservFirstFinancialConfig } from '@/configByPartner/fiservFirstFinancial.config';
import { SettingsCardIdEnum } from './cl/components/SettingsCard/SettingsCard.component';
import { sbbConfig } from './configByPartner/sbb.config';
import {
  capitalOneConfig,
  cloverConfig,
  fiservCitizensConfig,
  fiservConfig,
  fiservUsBankConfig,
  fiservXDDemoConfig,
  melioConfig,
  standardProviderConfig,
} from './configByPartner';
import { Features, PartnerConfig, PartnersConfig, SettingsGroupEnum } from './partnersConfig.types';

export const PartnersConfiguration: PartnersConfig = {
  melio: {
    displayName: 'Melio',
    partnerNameAnalyticsEvents: 'melio',
    partnerProductName: 'Melio',
    theme: melioTheme,
    config: melioConfig,
    icons: MelioIcons,
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
        orgId: 'orgId',
        userId: 'userId',
        terms: 'terms',
        mock: 'mock',
        target: 'target',
        targetId: 'targetId',
        entryPoint: 'entryPoint',
        redirectUrl: 'redirect_url',
      },
      redirectWhenMissingToken: true,
    },
    rolesMapping: {
      [RoleUniqueNames.MelioOwner]: 1,
      [RoleUniqueNames.MelioAdmin]: 2,
      [RoleUniqueNames.MelioAccountant]: 3,
      [RoleUniqueNames.MelioContributor]: 4,
    },
    supportEmail: 'support@meliopayments.com',
    showOnboardingSwiper: true,
    allowEditCompanyDetails: true,
    allowEditFirstAndLastName: true,
    hideFooter: true,
    skipLogoutSuccessful: true,
    features: {
      [Features.GetPaid]: {
        enabled: true,
      },
      [Features.AccountingFirm]: {
        enabled: true,
      },
      [Features.Expenses]: {
        enabled: true,
      },
      [Features.ReceivingMethods]: {
        learnMoreUrl: {
          zendeskLink:
            'https://melio.zendesk.com/hc/en-us/articles/4416227178908-How-can-I-pay-the-credit-card-fee-on-behalf-of-my-customers',
          melioPaymentsLink:
            'https://help.meliopayments.com/en/articles/5187223-how-can-i-pay-the-credit-card-fee-on-behalf-of-my-customer',
        },
      },
    },
    supportLegacyOrganizations: true,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: true,
      approvalByOtherActions: true,
    },
  },
  'capital-one': {
    displayName: 'Capital One Business',
    partnerNameAnalyticsEvents: 'capital-one',
    partnerProductName: 'Accounts Payable',
    theme: capOneTheme,
    config: capitalOneConfig,
    icons: CapOneIcons,
    supportEmail: 'capitaloneaccountspayable@melio.com',
    supportQuestionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'assertion',
        orgId: 'id',
        terms: 'acceptedTerms',
        target: 'target',
        targetId: 'targetId',
        entryPoint: 'entryPoint',
        userRole: 'userRole',
        backButtonReferenceId: 'id',
      },
    },
    rolesMapping: {
      [RoleUniqueNames.CapitalOnePrimary]: 1,
      [RoleUniqueNames.CapitalOneAm]: 2,
    },
    showOnboardingSwiper: true,
    showOnboardingBackToPartner: true,
    billsInbox: {
      learnMoreLink: 'https://capitalonevendorpayments.zendesk.com/hc/en-us/articles/6482836621852',
      showEmailLinkTitle: false,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: false,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
      billQbSynced: true,
    },
  },
  clover: {
    displayName: 'Clover',
    partnerNameAnalyticsEvents: 'clover',
    partnerProductName: 'Pay Bills',
    tabsVariant: 'neutral',
    theme: cloverTheme,
    config: cloverConfig,
    icons: CloverIcons,
    supportEmail: 'support-billpay@clover.melio.com',
    supportQuestionIds: [1, 2, 4, 5, 6, 3, 7, 8, 9, 10],
    rolesMapping: {
      [RoleUniqueNames.CloverOwner]: 1,
      [RoleUniqueNames.CloverAdmin]: 2,
    },
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'code',
        orgId: 'merchant_id',
        userId: 'employee_id',
      },
      redirectWhenMissingToken: true,
    },
    showOnboardingSwiper: false,
    billsInbox: {
      learnMoreLink: 'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5401742662812',
      showEmailLinkTitle: false,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: false,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
    },
  },
  fiserv: {
    displayName: 'Us Bank',
    partnerNameAnalyticsEvents: 'us-bank',
    partnerProductName: 'Pay Bills',
    theme: fiservTheme,
    config: fiservConfig,
    icons: UsBankIcons,
    supportEmail: 'us-bank-payments@melio.com',
    rolesMapping: {
      FiservLevel1: 1,
      FiservLevel2: 2,
      FiservLevel3: 3,
    },
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
      },
    },
    showOnboardingSwiper: false,
    billsInbox: {
      learnMoreLink: 'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5401742662812',
      showEmailLinkTitle: true,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: true,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
    },
  },
  'fiserv_xd-demo': {
    displayName: 'XD demo',
    partnerNameAnalyticsEvents: 'xd-demo-bank',
    partnerProductName: 'Pay Bills',
    theme: fiservTheme,
    config: fiservXDDemoConfig,
    icons: UsBankIcons,
    supportEmail: 'fiserv-xd-demo@melio.com',
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
      },
    },
    showOnboardingSwiper: false,
    billsInbox: {
      learnMoreLink: 'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5401742662812',
      showEmailLinkTitle: false,
    },
    rolesMapping: {
      [RoleUniqueNames.FiservLevel1]: 1,
      [RoleUniqueNames.FiservLevel2]: 2,
      [RoleUniqueNames.FiservLevel3]: 3,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: true,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
    },
  },
  'fiserv_us-bank': {
    displayName: 'Us Bank',
    partnerNameAnalyticsEvents: 'us-bank',
    partnerProductName: 'Pay Bills',
    theme: usBankTheme,
    config: fiservUsBankConfig,
    icons: UsBankIcons,
    supportEmail: 'us-bank-payments@melio.com',
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
      },
    },
    showOnboardingSwiper: false,
    billsInbox: {
      learnMoreLink: 'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5401742662812',
      showEmailLinkTitle: true,
    },
    rolesMapping: {
      [RoleUniqueNames.FiservLevel1]: 1,
      [RoleUniqueNames.FiservLevel2]: 2,
      [RoleUniqueNames.FiservLevel3]: 3,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: true,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
    },
  },
  'fiserv_first-financial': {
    displayName: 'First Financial',
    partnerNameAnalyticsEvents: 'first-financial',
    partnerProductName: 'Pay Bills',
    theme: fiservTheme,
    config: fiservFirstFinancialConfig,
    icons: FiservIcons,
    supportEmail: 'us-bank-payments@melio.com',
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
      },
    },
    rolesMapping: {
      [RoleUniqueNames.FiservLevel1]: 1,
      [RoleUniqueNames.FiservLevel2]: 2,
      [RoleUniqueNames.FiservLevel3]: 3,
    },
    showOnboardingSwiper: false,
    billsInbox: {
      learnMoreLink: 'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5401742662812',
      showEmailLinkTitle: true,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: true,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
    },
  },
  fiserv_citizens: {
    displayName: 'Citizens',
    partnerNameAnalyticsEvents: 'citizens-bank',
    partnerProductName: 'Pay Bills',
    theme: citizensTheme,
    config: fiservCitizensConfig,
    icons: FiservIcons,
    supportEmail: 'fiserv_citizens-payments@melio.com',
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
      },
    },
    showOnboardingSwiper: false,
    billsInbox: {
      learnMoreLink: 'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5401742662812',
      showEmailLinkTitle: true,
    },
    rolesMapping: {
      [RoleUniqueNames.FiservLevel1]: 1,
      [RoleUniqueNames.FiservLevel2]: 2,
      [RoleUniqueNames.FiservLevel3]: 3,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: true,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
    },
  } as PartnerConfig,
  'deployments-demo': {
    displayName: 'Us Bank',
    partnerNameAnalyticsEvents: 'us-bank',
    partnerProductName: 'Pay Bills',
    theme: fiservTheme,
    config: fiservUsBankConfig,
    icons: UsBankIcons,
    supportEmail: 'us-bank-payments@melio.com',
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
      },
    },
    showOnboardingSwiper: false,
    billsInbox: {
      learnMoreLink: 'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5401742662812',
      showEmailLinkTitle: false,
    },
    rolesMapping: {},
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: true,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
    },
  },
  shopify: {
    // shopify is not in use in the platform-app
  } as PartnerConfig,
  'built-technologies': {
    // Built is not in use in the platform-app
  } as PartnerConfig,
  // off-the-shelf partners that we'll eventually move to store the config in db
  gusto: {
    displayName: 'Gusto',
    partnerNameAnalyticsEvents: 'gusto',
    partnerProductName: 'Bill Pay',
    config: mergeWith(
      {},
      standardProviderConfig,
      {
        translationsSrc: 'https://platform-static.meliopayments.com/assets/gusto/messages.json',
        settings: {
          embeddedExperienceInsideScroll: true,
          showFooterInEmbeddedExperience: true,
          hideHeaderNavigation: true,
          showRetryButtonOnFieldValidation: false,
          settings: {
            options: [
              {
                type: SettingsGroupEnum.COMPANY,
                items: [
                  SettingsCardIdEnum.ProfileSettings,
                  SettingsCardIdEnum.CompanySettings,
                  SettingsCardIdEnum.CollaboratorsSetting,
                  SettingsCardIdEnum.NotificationPreferences,
                  SettingsCardIdEnum.AccountingSoftwareSync,
                ],
              },
              {
                type: SettingsGroupEnum.PAYMENTS,
                items: [
                  SettingsCardIdEnum.PaymentMethodsSetting,
                  SettingsCardIdEnum.SubscriptionPlansSettings,
                  SettingsCardIdEnum.BillingSetting,
                ],
              },
              {
                type: SettingsGroupEnum.SUPPORT,
                items: [SettingsCardIdEnum.SupportSetting],
              },
            ],
          },
          supportEmail: 'support@gustobillpay.melio.com',
          accountingSoftwareSuccessReturnUrl: 'https://app.gusto-demo.com/gusto-money/bill-pay',
          accountingSoftwareErrorReturnUrl: 'https://app.gusto-demo.com/gusto-money/bill-pay',
        },
        zendesk: {
          useNewWidgetApi: true,
        },
        services: {
          zendeskKey: 'a12b65fb-6c77-4e8f-928e-db79662ce203',
        },
        links: {
          'widgets.companySettings.header.sectionBanner.linkHref': 'https://app.gusto.com/help/contact',
        },
      },
      defaultConfig.production
        ? {
            settings: {
              accountingSoftwareSuccessReturnUrl: 'https://app.gusto.com/gusto-money/bill-pay',
              accountingSoftwareErrorReturnUrl: 'https://app.gusto.com/gusto-money/bill-pay',
            },
          }
        : {},
    ),
    theme: gustoTheme,
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
        target: 'target',
      },
    },
    icons: gustoIcons,
    supportedNotificationGroupsOptions: {
      billDueReminder: true,
      paymentByMeScheduled: true,
      paymentByMeUpdated: true,
      feeReceipt: true,
      paymentByOtherScheduled: false,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
      billQbSynced: true,
    },
  } as PartnerConfig,
  'optum-bank-demo': {
    displayName: 'Optum Bank',
    partnerNameAnalyticsEvents: 'optum-bank-demo',
    partnerProductName: 'Pay bills',
    config: mergeWith({}, standardProviderConfig, {
      settings: {
        isEmbeddedExperience: false,
        partnerProductName: 'Pay Bills',
        settings: {
          options: [
            {
              type: SettingsGroupEnum.COMPANY,
              items: [
                SettingsCardIdEnum.ProfileSettings,
                SettingsCardIdEnum.CompanySettings,
                SettingsCardIdEnum.CollaboratorsSetting,
                SettingsCardIdEnum.NotificationPreferences,
                SettingsCardIdEnum.AccountingSoftwareSync,
              ],
            },
            {
              type: SettingsGroupEnum.PAYMENTS,
              items: [
                SettingsCardIdEnum.PaymentMethodsSetting,
                SettingsCardIdEnum.SubscriptionPlansSettings,
                SettingsCardIdEnum.BillingSetting,
              ],
            },
            {
              type: SettingsGroupEnum.SUPPORT,
              items: [SettingsCardIdEnum.SupportSetting],
            },
          ],
        },
      },
    }),
    theme: optumBankTheme,
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'token',
      },
    },
    icons: OptumBankIcons,
  } as PartnerConfig,
  paypal: {
    displayName: 'PayPal',
    partnerNameAnalyticsEvents: 'paypal',
    partnerProductName: 'Pay bills',
    config: mergeWith({}, standardProviderConfig, {
      settings: {
        isEmbeddedExperience: false,
        settings: {
          options: [
            {
              type: SettingsGroupEnum.COMPANY,
              items: [
                SettingsCardIdEnum.ProfileSettings,
                SettingsCardIdEnum.CompanySettings,
                SettingsCardIdEnum.CollaboratorsSetting,
                SettingsCardIdEnum.NotificationPreferences,
                SettingsCardIdEnum.AccountingSoftwareSync,
              ],
            },
            {
              type: SettingsGroupEnum.PAYMENTS,
              items: [
                SettingsCardIdEnum.PaymentMethodsSetting,
                SettingsCardIdEnum.SubscriptionPlansSettings,
                SettingsCardIdEnum.BillingSetting,
              ],
            },
            {
              type: SettingsGroupEnum.SUPPORT,
              items: [SettingsCardIdEnum.SupportSetting],
            },
          ],
        },
      },
    }),
    theme: payPalTheme,
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'code',
      },
      redirectWhenMissingToken: true,
    },
    icons: PayPalIcons,
  } as PartnerConfig,
  'wells-fargo': {
    // ?????
  } as PartnerConfig,
  sbb: {
    displayName: 'Capital One Business',
    partnerNameAnalyticsEvents: 'sbb',
    partnerProductName: 'Pay Business Bills',
    theme: capOneTheme,
    config: sbbConfig,
    icons: CapOneIcons,
    supportEmail: 'support@capitalonebusinessbills.melio.com',
    supportQuestionIds: [1, 5, 2, 13, 8, 10, 9, 3, 11, 12],
    oAuth: {
      required: true,
      queryParamsMapping: {
        token: 'assertion',
        orgId: 'bid',
        terms: 'acceptedTerms',
        target: 'target',
        targetId: 'targetId',
        entryPoint: 'entryPoint',
        backButtonReferenceId: 'id',
      },
    },
    rolesMapping: {
      [RoleUniqueNames.CapitalOnePrimary]: 1,
      [RoleUniqueNames.CapitalOneAm]: 2,
    },
    showOnboardingSwiper: true,
    billsInbox: {
      learnMoreLink:
        'https://capitalonebusinessbills.zendesk.com/hc/en-us/articles/9702494960668-How-to-automatically-receive-bills-to-your-Bills-inbox',
      showEmailLinkTitle: false,
    },
    allowEditCompanyDetails: false,
    allowEditFirstAndLastName: false,
    supportedNotificationGroupsOptions: {
      paymentByMeScheduled: false,
      paymentByMeUpdated: false,
      billDueReminder: true,
      feeReceipt: true,
      paymentByOtherScheduled: true,
      approvalByMeRequired: false,
      approvalByOtherActions: false,
    },
  },
};
