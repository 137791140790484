import { RoleUniqueNames } from '@melio/platform-api';
import { PartialMelioConfig } from '@melio/platform-provider';

import { extendDefaultConfig, PartnerConfigByEnv } from '@/configByPartner/partnerConfig.utils';
import { melioLinks } from '@/links';

const defaultMelioConfig: PartialMelioConfig = {
  tabTitle: 'Melio',
  settings: {
    supportEmail: 'support@melio.com',
    isRequestCallbackEnabled: true,
    collaborator: {
      rolesOrderMapping: {
        [RoleUniqueNames.MelioOwner]: 1,
        [RoleUniqueNames.MelioAdmin]: 2,
        [RoleUniqueNames.MelioAccountant]: 3,
        [RoleUniqueNames.MelioContributor]: 4,
      },
    },
    fundingSourcePolicy: {
      'flex-account': {
        order: 0,
        fundingSourceGroup: 'flex-account',
        read: true,
        write: true,
      },
      'bank-account': {
        order: 1,
        fundingSourceGroup: 'bank-account',
        read: true,
        write: true,
      },
      credit: {
        order: 2,
        fundingSourceGroup: 'card',
        read: true,
        write: true,
      },
      debit: {
        order: 3,
        fundingSourceGroup: 'card',
        read: true,
        write: true,
      },
    },
    vex: {
      unilateral: {
        isEmailLoginEnabled: true,
        shouldSuggestJoinMelio: true,
        isNewUnilateral: true,
        suvcInformationLink: 'https://meliopayments.com/guides/single-use-virtual-cards/',
      },
    },
    subscriptions: {
      isGracePeriodSupported: true,
      claimSuggestedPlan: true,
      links: {
        seeAllFeatures: 'https://meliopayments.com/pricing/',
        learnMore: {
          smb: 'https://go.meliopayments.com/learnmore/smb',
          accountingClients: 'https://go.meliopayments.com/learnmore/clients',
          accountingFirmsV2: 'https://go.meliopayments.com/learnmore/accountants-new',
          accountingFirmsV1: 'https://go.meliopayments.com/learnmore/accountants',
        },
      },
      isPaymentFeeFailedGracePeriodEnabled: true,
    },
    settings: {
      options: [
        {
          type: 'company',
          items: [
            'profile-settings',
            'company-settings',
            'collaborators-setting',
            'notification-preferences',
            'workflows-setting',
            'accounting-software-sync',
            'tax-and-reports',
          ],
        },
        {
          type: 'payments',
          items: [
            'payment-methods-setting',
            'subscription-plans-setting',
            'billing-setting',
            'receiving-methods-settings',
          ],
        },
        {
          type: 'ar',
          items: ['receiving-methods-settings', 'invoice-items', 'invoice-settings', 'invoice-email-notifications'],
        },
        {
          type: 'support',
          items: ['support-setting'],
        },
      ],
    },
    guestPaymentFlow: {
      shouldGenerateDeepLinkToPaymentRequestPayDashboard: true,
    },
    InternationalFx: {
      enabled: true,
      flagName: 'international-fx',
    },
    MarkAsPaid: {
      enabled: true,
    },
    File1099SettingsTaxAndReports: {
      flagName: 'platform-feature-1099-tax-and-reports-settings',
      enabled: true,
    },
    Import1099Contractors: {
      flagName: '1099-import-contractors',
      enabled: true,
    },
    File1099PreSeasonBanner: {
      flagName: '1099-pre-season-banners-dashboard',
      enabled: true,
    },
    OutdatedSessionModal: {
      enabled: true,
      flagName: 'platform-outdated-session-modal',
    },
    BillingFeeSettingsForExternalAccountants: {
      flagName: 'enable-billing-fee-settings-for-external-accountant',
      enabled: true,
    },
  },
  services: {
    forethoughtApiKey: '',
  },
  zendesk: {
    useNewWidgetApi: true,
  },
  links: melioLinks,
  translationsSrc: 'https://platform-static.meliopayments.com/assets/melio/messages.json',
};

const prodMelioConfig: PartialMelioConfig = {
  services: {
    zendeskKey: '51fdfad6-7341-4cb2-8eb8-88ccece82bbc',
  },
};

const melioPartialConfig: PartnerConfigByEnv = {
  default: defaultMelioConfig,
  dev: {},
  prod: prodMelioConfig,
};

export const melioConfig = extendDefaultConfig(melioPartialConfig);
