import { IconProps } from '@chakra-ui/react';
import { TabsProps } from '@melio/penny';
import { NotificationPreferences, PartnerName } from '@melio/platform-api';
import { MelioConfig, Theme } from '@melio/platform-provider';

import { SettingsCardIdEnum } from './cl/components/SettingsCard/SettingsCard.component';
import { ReceivingMethodsFeature } from './types/featuresConfig';

export type IconType = (props: IconProps) => JSX.Element;

export type AuthQueryParamsKeyMapping = {
  token: string;
  orgId?: string;
  userId?: string;
  terms?: string;
  mock?: string;
  target?: string;
  targetId?: string;
  entryPoint?: string;
  redirectUrl?: string;
  userRole?: string;
  backButtonReferenceId?: string;
};

export type TokenProvider = 'tbt' | 'tabapay';

export const partnerHostToPartnerName: { [key: string]: string } = {
  'vendorpayments-qa.capitalone.com': 'capital-one',
  'vendorpayments.capitalone.com': 'capital-one',
  'billpay-clover.by.melio.com': 'clover',
  'app.melio.com': 'melio',
};

export const tenantToPartnerConfiguration: { [key: string]: { partnerName: string; basePath: string } } = {
  '1403': { partnerName: PartnerName.FiservUsBank, basePath: 'cfc' },
  '12858': { partnerName: PartnerName.FiservFirstFinancial, basePath: 'cfc' },
  '9403': { partnerName: PartnerName.FiservFirstFinancial, basePath: 'cfc' },
  AG0002: { partnerName: 'fiserv_citizens', basePath: 'cfc' },
};

type OAuthConfiguration =
  | {
      required: true;
      queryParamsMapping: AuthQueryParamsKeyMapping;
      redirectWhenMissingToken?: boolean;
    }
  | {
      required: false;
    };

type BillsInboxConfiguration = {
  learnMoreLink: string;
  showEmailLinkTitle: boolean;
};

export type Icon = {
  small: string;
  extraSmall?: string;
};

export type PartnerIcons = {
  Favicon: string;
  MelioIcon: Icon;
  LogoIcon: Icon;
  ErrorIcon: Icon;
  EmptyBillsIcon: Icon;
  EmptyVendorsIcon: Icon;
  NoBillsFoundIcon: Icon;
  NoVendorsFoundIcon: Icon;
  VerifyEmailIcon: Icon;
  TaxSummaryIcon: Icon;
  ExpiredSessionIcon?: Icon;
  BackToPartnerIcon: Icon;
  StartNewExperienceIcon: Icon;
  TryDifferentEmailIcon: Icon;
  AddBillIcon: Icon;

  ConsentHeader?: Icon;
  ConsentImage1?: Icon;
  ConsentImage2?: Icon;
};

export enum Features {
  GetPaid = 'getPaid',
  ReceivingMethods = 'receivingMethods',
  Expenses = 'expenses',
  AccountingFirm = 'accountingFirm',
}

type Feature = {
  enabled: boolean;
};

type FeatureConfig = {
  [Features.GetPaid]?: Feature;
  [Features.Expenses]?: Feature;
  [Features.ReceivingMethods]?: ReceivingMethodsFeature;
  [Features.AccountingFirm]?: Feature;
};

export enum SettingsGroupEnum {
  COMPANY = 'company',
  PAYMENTS = 'payments',
  AR = 'ar',
  SUPPORT = 'support',
}

export type PartnerSettingsGroupConfig = {
  type: SettingsGroupEnum;
  items: SettingsCardIdEnum[];
};

export type PartnerSettingsConfig = PartnerSettingsGroupConfig[];

export interface PartnerConfig {
  displayName: string;
  partnerNameAnalyticsEvents: string;
  partnerProductName: string;
  tabsVariant?: TabsProps['variant'];
  theme: Theme;
  config: MelioConfig;
  icons: PartnerIcons;
  supportEmail?: string;
  supportQuestionIds?: number[]; //TODO make default
  oAuth: OAuthConfiguration;
  showOnboardingSwiper: boolean;
  showOnboardingBackToPartner?: boolean;
  redirectOnConsentScreenReject?: boolean;
  billsInbox?: BillsInboxConfiguration;
  allowEditCompanyDetails: boolean;
  allowEditFirstAndLastName: boolean;
  features?: FeatureConfig;
  hideFooter?: boolean;
  skipLogoutSuccessful?: boolean;
  rolesMapping: Record<string, number>;
  supportedNotificationGroupsOptions: Partial<NotificationPreferences>;
  supportLegacyOrganizations?: boolean;
}

export interface PartnersConfig {
  'capital-one': PartnerConfig;
  clover: PartnerConfig;
  melio: PartnerConfig;
  shopify: PartnerConfig;
  'built-technologies': PartnerConfig;
  'wells-fargo': PartnerConfig;
  sbb: PartnerConfig;
  fiserv: PartnerConfig;
  'fiserv_us-bank': PartnerConfig;
  'fiserv_xd-demo': PartnerConfig;
  fiserv_citizens: PartnerConfig;
  'fiserv_first-financial': PartnerConfig;
  'deployments-demo': PartnerConfig;
  // off-the-shelf partners that we'll eventually move to store the config in db
  gusto: PartnerConfig;
  ['optum-bank-demo']: PartnerConfig;
  paypal: PartnerConfig;
}
