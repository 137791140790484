/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { routes as ACCOUNTANTS_ROUTES } from '@melio/accountants';
import { AccountingPlatformSyncConnectionErrorEnum, SyncFlowEnum, useActivitiesNavigate } from '@melio/ap-activities';
import { OriginFlow } from '@melio/platform-analytics';
import { useAnalytics } from '@melio/platform-analytics';
import { ApiError, ApprovalWorkflow, CardType } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

import { SettingsNestedPages } from '@/consts/SettingsConsts';
import { usePartnerConfig, usePartnerLogic } from '@/hooks/partners';
import { appSelectedActiveScreenSelector } from '@/store/app/app.model';
import { DeliveryMethodNavigationStateParams } from '@/types/deliveryMethod.types';
import { PayDashboardTabs } from '@/types/payDashboard.types';
import { generateMelioRedirectPath } from '@/utils/generateMelioRedirectPath';
import { getPartnerUrlPrefixFromUrl } from '@/utils/partner.utils';
import { useIsNewDashboardEnabled } from './useIsNewDashboardEnabled';

export type AddNewBillStateType = {
  file?: File;
  amount?: string;
  categoryId?: string | null;
  vendorId?: string;
  deliveryMethodId?: string;
  originFlow: OriginFlow;
  returnUrl?: string;
  files?: File[];
  isAdvancedView?: boolean;
  waitingForMessage?: boolean;
};

export type AccountingPlatformStateType = {
  syncError?: AccountingPlatformSyncConnectionErrorEnum;
  accountingPlatformId?: string;
};

export type AccountingPlatformErrorStateType = {
  syncError: AccountingPlatformSyncConnectionErrorEnum;
  flowToReturn?: SyncFlowEnum;
  accountingPlatformId?: string;
};

export type AccountingPlatformSyncStateType = {
  accountingPlatformId: string;
  flowToReturn?: SyncFlowEnum;
};

export type AddCardRouteStateType = {
  cardType?: CardType;
  returnUrl?: string;
};

export type SchedulePaymentRouteParamsType = {
  billId: string;
  deliveryMethodId?: string;
  returnUrl?: string;
};

export type SchedulePaymentRequestRouteParams = {
  paymentRequestId: string;
  returnUrl?: string;
};

export type AddCompanyRouteParams = {
  entryPoint: string;
};

export type ReviewScannedInvoiceStateType = {
  returnUrl?: string;
  origin?: OriginFlow;
};

interface RouterOptions {
  replace?: boolean;
}

const generateFailedToDeliverLink = (paymentId: string) => `/schedule-payment/${paymentId}/retry-deliver`;

const generateNPEDashboardLink = (tab?: string, entityId?: string) =>
  `/pay-dashboard${tab ? `/${tab}` : ''}${entityId && tab === 'vendors' ? '/vendor' : ''}${
    entityId ? `/${entityId}` : ''
  }`;

const generatePayDashboardTabLink = (
  isMigratedToNewPayDashboard: boolean,
  tabName: PayDashboardTabs,
  inboxItemId?: string,
): string => {
  if (isMigratedToNewPayDashboard) {
    const shouldGoToPayments = [PayDashboardTabs.Paid, PayDashboardTabs.Scheduled].includes(tabName);
    const npeTabName = shouldGoToPayments ? 'payments' : 'vendors';
    return generateNPEDashboardLink(npeTabName);
  }

  return `/pay/${tabName}${inboxItemId ? `/${inboxItemId}` : ''}`;
};

export const useRouter = () => {
  const isNPEEnabled = useIsNewDashboardEnabled();
  // TODO: change this flag to a more generic name to be used by all partners
  const [isCapOnePortalEnabled] = useDevFeature(FeatureFlags.isCapOnePortalEnabled, false);
  const [isNewPaymentFlowEnabled] = useDevFeature(FeatureFlags.NewPaymentFlow, false);
  const [isAccountantsNewFlowsEnabled] = useDevFeature(FeatureFlags.AccountantsNewFlows, false);
  const navigate = useNavigate();
  const activeScreen = useRecoilValue(appSelectedActiveScreenSelector);
  const { generateBackToPartnerUrl } = usePartnerLogic();
  const { track } = useAnalytics();
  const { partnerConfig } = usePartnerConfig();
  const goToStart = () => navigate('/start');

  const activitiesRouter = useActivitiesNavigate();
  type NavigationOptions = {
    keepToast?: boolean;
  };

  return React.useMemo(() => {
    const navigation = {
      ...activitiesRouter,
      generateRedirectUrl: (redirectUrl: string) => `/${redirectUrl}`,
      goToAppRedirect: () => navigate('/redirect'),
      refresh: () => navigate(0),
      goBack: () => navigate(-1),
      generateBackToPartnerUrl: () => {
        const backUrl = `${getPartnerUrlPrefixFromUrl()}/start`;
        return generateBackToPartnerUrl() ?? backUrl;
      },
      goForward: () => navigate(1),
      goHome: ({ shouldRefreshPage = false } = {}) => {
        shouldRefreshPage ? (location.href = `${getPartnerUrlPrefixFromUrl()}`) : navigate('/');
      },
      goToAccountingPlatform: (
        { syncError, accountingPlatformId }: AccountingPlatformStateType,
        options?: NavigationOptions,
      ) =>
        navigate('/settings/accounting-software', {
          state: {
            syncError,
            accountingPlatformId,
            keepToast: options?.keepToast,
          },
        }),
      goToCreateApprovalWorkflow: () => navigate('/approval-workflows/new'),
      goToEditApprovalWorkflow: (id: ApprovalWorkflow['id']) => navigate(`/approval-workflows/${id}`),
      goToAccountingPlatformError: ({
        syncError,
        flowToReturn,
        accountingPlatformId,
      }: AccountingPlatformErrorStateType) =>
        navigate('/accounting-software/error', {
          state: {
            syncError,
            flowToReturn,
            accountingPlatformId,
          },
        }),
      goToAccountingPlatformSync: ({ accountingPlatformId, flowToReturn }: AccountingPlatformSyncStateType) =>
        navigate('/accounting-software/sync', {
          state: {
            accountingPlatformId,
            flowToReturn,
          },
        }),
      goToPathname: (replace = true) => {
        const partnerUrlPrefix = getPartnerUrlPrefixFromUrl();
        navigate(window.location.pathname.slice(partnerUrlPrefix.length), { replace });
      },
      goToPayDashboard: (options?: NavigationOptions) => {
        const defaultDashboardUrl = '/pay';
        const partnerDashboardUrl = generateBackToPartnerUrl();

        return isCapOnePortalEnabled && partnerDashboardUrl
          ? (window.location.href = partnerDashboardUrl)
          : navigate(defaultDashboardUrl, { state: { keepToast: options?.keepToast } });
      },
      goToStart,
      pushPayDashboardTab: (tabName: PayDashboardTabs, inboxItemId?: string) =>
        navigate(`/pay/${tabName}${inboxItemId ? `/${inboxItemId}` : ''}`),
      replaceToPayDashboardTab: (tabName: PayDashboardTabs, inboxItemId?: string, options?: NavigationOptions) => {
        const defaultDashboardUrl = generatePayDashboardTabLink(isNPEEnabled, tabName, inboxItemId);
        const partnerDashboardUrl = generateBackToPartnerUrl();

        return isCapOnePortalEnabled && partnerDashboardUrl
          ? (window.location.href = partnerDashboardUrl)
          : navigate(defaultDashboardUrl, { replace: true, state: { keepToast: options?.keepToast } });
      },
      goToNewDashboardTab: (tabName: string) => navigate(generateNPEDashboardLink(tabName)),
      goToPaySelectedInboxItem: (activeTab: string, inboxItemId: string, search: string, options: RouterOptions) => {
        navigate(`/pay/${activeTab}/${inboxItemId}${search}`, options);
      },
      goToReviewScannedInvoice: ({
        scannedInvoiceId,
        returnUrl,
        originFlow,
      }: {
        scannedInvoiceId: string;
        returnUrl?: string;
        originFlow?: OriginFlow;
      }) => {
        const path = `/review-draft/new/${scannedInvoiceId}`;
        return navigate(path, { state: { returnUrl, origin: originFlow } });
      },
      generateReviewScannedInvoiceLink: ({ scannedInvoiceId }: { scannedInvoiceId: string }) => {
        return `/review-draft/new/${scannedInvoiceId}`;
      },
      generateNPEDashboardLink: generateNPEDashboardLink,
      generateSchedulePaymentLink: (billId: string) => `/schedule-payment/${billId}`,
      goToApprovePaymentRequest: ({ paymentRequestId, returnUrl }: SchedulePaymentRequestRouteParams) =>
        navigate(`/schedule-payment/payment-request/${paymentRequestId}`, {
          state: { returnUrl },
        }),
      goToRetryFailedToCollectPayment: (paymentId: string) => navigate(`/schedule-payment/${paymentId}/retry-collect`),
      goToRetryFailedToDeliverPayment: (paymentId: string) => navigate(generateFailedToDeliverLink(paymentId)),
      generateFailedToDeliverLink: generateFailedToDeliverLink,
      goToRefundPayment: (paymentId: string) => navigate(`/schedule-payment/${paymentId}/refund`),
      goToVoidAndRefundPayment: (paymentId: string) => navigate(`/schedule-payment/${paymentId}/void-and-refund`),
      goToVoidAndResendPayment: (paymentId: string) => navigate(`/schedule-payment/${paymentId}/void-and-resend`),
      generateBatchPaymentsLink: (commaSeparatedBillIds: string) => `/batch-payments/${commaSeparatedBillIds}`,
      goToAddNewBill: (
        {
          vendorId = undefined,
          deliveryMethodId = undefined,
          originFlow,
          file,
          amount,
          categoryId,
          returnUrl,
          isAdvancedView,
          waitingForMessage,
        }: AddNewBillStateType,
        options?: NavigationOptions,
      ) =>
        navigate(isNewPaymentFlowEnabled ? '/bills/new' : '/schedule-payment/new', {
          state: {
            vendorId,
            deliveryMethodId,
            origin: originFlow,
            returnUrl,
            file,
            amount,
            categoryId,
            isAdvancedView,
            keepToast: options?.keepToast,
            waitingForMessage,
          },
        }),
      goToAddNewBillManual: ({ vendorId = undefined, originFlow, returnUrl }: AddNewBillStateType) =>
        navigate(isNewPaymentFlowEnabled ? '/bills/new/manual' : '/schedule-payment/new/manual', {
          state: { vendorId, origin: originFlow, returnUrl },
        }),
      goToAddNewBillUpload: ({ vendorId = undefined, originFlow, returnUrl, files }: AddNewBillStateType) =>
        navigate(isNewPaymentFlowEnabled ? '/bills/new/upload' : '/schedule-payment/new/upload', {
          state: { vendorId, origin: originFlow, returnUrl, files },
        }),
      goToAddNewCompany: ({ entryPoint }: AddCompanyRouteParams) =>
        navigate('/companies/new-company', { state: { entryPoint } }),
      goToEditBill: ({ id, returnUrl, originFlow }: { id: string; returnUrl?: string; originFlow?: OriginFlow }) => {
        navigate(`/bills/${id}`, { state: { returnUrl, origin: originFlow } });
      },
      generateContextualOnboardingLink: () => {
        return '/onboarding';
      },
      generateArLink: () => '/ar/dashboard/invoices',
      goToContextualOnboarding: () => {
        navigate('onboarding');
      },
      goToSuccessfulQboConnectLink: () => {
        navigate('/onboarding/successful-qbo-connect');
      },
      generateJustPayLink: () => {
        return isNewPaymentFlowEnabled ? '/bills/new/manual' : '/schedule-payment/new/manual';
      },
      goToEditPayment: ({ id, returnUrl }: { id: string; returnUrl?: string }) =>
        navigate(`/schedule-payment/${id}/edit`, { state: { returnUrl } }),
      generateEditPaymentLink: (paymentId: string) => `/schedule-payment/${paymentId}/edit`,
      goToVendor: (id: string, options?: NavigationOptions) =>
        navigate(`/vendors/${id}`, { state: { keepToast: options?.keepToast } }),
      goToVendors: () => navigate('/vendors'),
      goToVendorDeliveryMethods: ({ id, returnUrl }: { id: string; returnUrl?: string }) =>
        navigate(`/vendors/${id}/delivery-methods`, { state: { returnUrl } }),
      goToSettings: () => navigate('/settings'),
      goToSettingsAccountSoftware: () => navigate(`/settings/${SettingsNestedPages.ACCOUNT_SOFTWARE}`),
      goToSettingsBilling: () => navigate(`/settings/${SettingsNestedPages.BILLING}`),
      goToSettingsSubscriptionPlans: (options?: { refresh?: boolean }) => {
        const settingsSubscriptionPlansLink = `/settings/${SettingsNestedPages.SUBSCRIPTION_PLANS}`;
        if (options?.refresh) {
          const baseName = getPartnerUrlPrefixFromUrl();
          location.href = `${baseName}${settingsSubscriptionPlansLink}`;
          return;
        }
        navigate(settingsSubscriptionPlansLink);
      },
      goToAddBillingMethodAccountant: () => navigate('/accountants/billing-settings/add'),
      goToEditBillingMethodAccountant: () => navigate('/accountants/billing-settings/edit/funding-source'),
      goToEditBillingMethodClientsAccountant: () => navigate('/accountants/billing-settings/edit/clients'),
      goToAddBillingMethod: () => navigate('/billing-fee'),
      goToEBillsSubscription: (vendorId: string) => navigate(`/vendors/${vendorId}/ebills-subscription`),
      goToAutoPaymentActivation: (vendorId: string) => navigate(`/vendors/${vendorId}/auto-payment-activation/new`),
      goToAutoPaymentEdit: (vendorId: string) => navigate(`/vendors/${vendorId}/auto-payment-activation/edit`),
      goToSettingsCompany: () => navigate(`/settings/${SettingsNestedPages.COMPANY}`),
      goToSettingsNotificationPreferences: () => navigate(`/settings/${SettingsNestedPages.NOTIFICATION_PREFERENCES}`),
      goToSettingsCollaborators: () => navigate(`/settings/${SettingsNestedPages.COLLABORATORS}`),
      goToSettingsWorkflows: (props?: { newWorkflowCreated: boolean; workflowId?: string }) =>
        navigate(`/settings/${SettingsNestedPages.WORKFLOWS}`, {
          state: props,
        }),
      goToSettingsPaymentMethods: () => navigate(`/settings/${SettingsNestedPages.PAYMENT_METHODS}`),
      goToSettingsPaymentMethodsMicroDeposits: (fundingSourceId: string) =>
        navigate(`/settings/${SettingsNestedPages.PAYMENT_METHODS}/${fundingSourceId}/verify`),
      goToSettingsProfile: () => navigate(`/settings/${SettingsNestedPages.PROFILE}`),
      goToSettingsSupport: () => navigate(`/settings/${SettingsNestedPages.SUPPORT}`),
      goToSettingsTaxAndReports: () => navigate(`/settings/${SettingsNestedPages.TAX_AND_REPORTS}`),
      goToReceivingMethods: () => navigate(`/settings/${SettingsNestedPages.RECEIVING_METHODS}`),
      goToInvoiceItems: () => navigate(`/settings/${SettingsNestedPages.INVOICE_ITEMS}`),
      goToInvoicePreferences: () => navigate(`/settings/${SettingsNestedPages.INVOICE_SETTINGS}`),
      goToInvoiceEmailNotifications: () => navigate(`/settings/${SettingsNestedPages.INVOICE_EMAIL_NOTIFICATIONS}`),
      goToBankAccountSelect: () => navigate('/payment-methods/bank/select'),
      goToAddCard: ({ cardType }: AddCardRouteStateType) => navigate('/payment-methods/card', { state: { cardType } }),
      goToAddNewVendor: ({ returnUrl }: { returnUrl?: string } = {}) =>
        navigate('/vendors/new-vendor', { state: { returnUrl } }),
      goToAddNewVendorDeliveryMethod: (id: string, method: string) =>
        navigate(`/vendors/${id}/delivery-methods/${method}`),
      goToPayDashboardTabLinkAndRefresh: (tabName: PayDashboardTabs, inboxItemId?: string) => {
        const baseName = getPartnerUrlPrefixFromUrl();
        const payDashboardTabLink = generatePayDashboardTabLink(false, tabName, inboxItemId);
        location.href = `${baseName}${payDashboardTabLink}`;
      },
      generateVendorBankAccountLink: ({ id, returnUrl }: DeliveryMethodNavigationStateParams) => ({
        path: `/vendors/${id}/delivery-methods/bank-account`,
        options: { state: { returnUrl } },
      }),
      generateVendorVirtualCardLink: ({ id, returnUrl }: DeliveryMethodNavigationStateParams) => ({
        path: `/vendors/${id}/delivery-methods/virtual-card`,
        options: { state: { returnUrl } },
      }),
      generateVendorDomesticAccountLink: ({ id, returnUrl }: DeliveryMethodNavigationStateParams) => ({
        path: `/vendors/${id}/delivery-methods/domestic-wire`,
        options: { state: { returnUrl } },
      }),
      generateVendorInternationalAccountLink: ({ id, returnUrl }: DeliveryMethodNavigationStateParams) => ({
        path: `/vendors/${id}/delivery-methods/international-account`,
        options: { state: { returnUrl } },
      }),
      generateVendorInternationalFXAccountLink: ({ id, returnUrl }: DeliveryMethodNavigationStateParams) => ({
        path: `/vendors/${id}/delivery-methods/international-fx`,
        options: { state: { returnUrl } },
      }),

      generateVendorPaperCheckLink: ({ id, returnUrl }: DeliveryMethodNavigationStateParams) => ({
        path: `/vendors/${id}/delivery-methods/paper-check`,
        options: { state: { returnUrl } },
      }),
      generatePayScheduledLink: (id: string) => `/pay/scheduled?vendor=${id}`,
      generatePayPaidLink: (id: string) => `/pay/paid?vendor=${id}`,
      generatePayInboxLink: (id: string) => `/pay/inbox?vendor=${id}`,
      generatePayTabLink: () => '/pay',
      generateARTabLink: () => '/ar/dashboard',
      generatePayDashboardTabLink: (tabName: PayDashboardTabs, inboxItemId?: string) =>
        `/pay/${tabName}${inboxItemId ? `/${inboxItemId}` : ''}`,
      generateViewBillLink: (billId?: string) => {
        if (isNPEEnabled) {
          return navigation.generateNPEDashboardLink('bills', billId);
        }
        return navigation.generatePayDashboardTabLink(PayDashboardTabs.Inbox, billId);
      },
      generateViewPaymentLink: (
        paymentId?: string,
        status: PayDashboardTabs.Scheduled | PayDashboardTabs.Paid = PayDashboardTabs.Scheduled,
      ) => {
        if (isNPEEnabled) {
          return navigation.generateNPEDashboardLink('payments', paymentId);
        }
        return navigation.generatePayDashboardTabLink(status, paymentId);
      },
      generateViewVendorLink: (vendorId?: string) => navigation.generateNPEDashboardLink('vendors', vendorId),
      generateSettingsTabLink: (nestedPage?: string) => `/settings/${nestedPage ?? ''}`,
      generateSettingsProfileTabLink: () => `/settings/${SettingsNestedPages.PROFILE}`,
      generateErrorLink: (error?: ApiError | null) => {
        if (error?.code === '401') {
          return '/unauthorized';
        } else {
          return '/error';
        }
      },
      generateCustomersLink: (accessToken: string) =>
        partnerConfig.features?.getPaid?.enabled ? generateMelioRedirectPath(accessToken, 'contacts/customers') : null,
      generateGetPaidDashboardLink: (accessToken: string) =>
        partnerConfig.features?.getPaid?.enabled ? generateMelioRedirectPath(accessToken, 'get-paid') : null,
      generateExpensesLink: (accessToken: string) =>
        partnerConfig.features?.expenses?.enabled ? generateMelioRedirectPath(accessToken, 'spend-management') : null,
      generateMelioClientsLink: (accessToken: string, withBase: boolean = false) => {
        if (!partnerConfig.features?.accountingFirm) {
          return null;
        }
        if (!isAccountantsNewFlowsEnabled) {
          return generateMelioRedirectPath(accessToken, 'companies');
        }

        const base = withBase ? getPartnerUrlPrefixFromUrl() : '';
        return base + ACCOUNTANTS_ROUTES.DASHBOARD;
      },
      generateMelioTeamLink: (accessToken: string) =>
        partnerConfig.features?.accountingFirm?.enabled ? generateMelioRedirectPath(accessToken, 'team') : null,
      generateExternalEntriesFallbackErrorLink: () => '/external-entries/fallback-error',
      goToUnilateral: () => navigate('/accept'),
      goToNewUnilateral: (paymentId: string) => navigate(`/vex/unilateral/${paymentId}`),
      goToVexPaymentUpgrade: (paymentId: string) => navigate(`/vex/payment-upgrade/${paymentId}/index`),
      goToVexVendorPaymentTracking: (paymentId: string) => navigate(`/vex/track-payment/${paymentId}`),
      goToSuvcAcceptance: (paymentId: string) => navigate(`/vex/suvc-acceptance/${paymentId}`),
      goToShiftVirtualCardToACH: (paymentId: string) => navigate(`/vex/suvc-acceptance/${paymentId}/shift-suvc-to-ach`),
      goToCollectW9: (vendorId: string) => navigate(`/vex/collect-w9/${vendorId}`),
      goToVendorOnboarding: (id: string) => navigate(`/vex/vendor-onboarding/${id}`),
      goToUpgradePayment: () => navigate('/upgrade-payment'),
      goToLogoutSuccessful: () => {
        if (partnerConfig.skipLogoutSuccessful) {
          goToStart();
        } else {
          navigate('/logout-successful');
        }
      },
      goToAuth: () => navigate('/auth'),
      goToSessionExpired: () => navigate('/expired-session'),
      goToAccountsDashboard: () => navigate('/accounts'),
      generateAccountsDashboardLink: () => '/accounts',
      generateSettingsPaymentMethodsMicroDepositsLink: (fundingSourceId: string) =>
        `/settings/${SettingsNestedPages.PAYMENT_METHODS}/${fundingSourceId}/verify`,

      goToAddReceivingMethod: (isOwnedVendorSettings = false) =>
        navigate(`/${SettingsNestedPages.RECEIVING_METHODS}/add-from-get-paid-settings/select`, {
          state: { origin: { isOwnedVendorSettings } },
        }),
      goViewReceivingMethodBankAccount: (id: string) =>
        navigate(`/${SettingsNestedPages.RECEIVING_METHODS}/ach/manual/${id}`),
      goToEditPlaidBankAccount: (id: string) => navigate(`/${SettingsNestedPages.RECEIVING_METHODS}/ach/plaid/${id}`),
      goToEditManualBankAccount: (id: string) => navigate(`/${SettingsNestedPages.RECEIVING_METHODS}/ach/select/${id}`),
      goToCantFindBankAccountScreen: (id: string) =>
        navigate(`/${SettingsNestedPages.RECEIVING_METHODS}/ach/cant-find/${id}`),
      goToAddAchFromReceivingMethodFlow: (id: string) => {
        navigate(`/${SettingsNestedPages.RECEIVING_METHODS}/ach/edit/${id}/bank-details`);
      },
      goToReceivingMethodLinkBankAccount: (id: string) =>
        navigate(`/${SettingsNestedPages.RECEIVING_METHODS}/ach/link-bank-account/${id}`),
      goToDemoCallback: (accessToken: string, accountId: string) =>
        navigate(`/auth/demo-openid/callback?google_access_token=${accessToken}&account_id=${accountId}`),
      goToEditBillSubscription: ({ id, returnUrl }: { id: string; returnUrl?: string }) =>
        navigate(`/bill-subscription/${id}/edit`, { state: { returnUrl } }),
      goToCompleteRequiredDetails: ({ returnUrl }: { returnUrl?: string } = {}) =>
        navigate(`/complete-details${returnUrl ? `?returnUrl=${returnUrl}` : ''}`),
      goToTimeout: () => {
        // we use redirect because there are error toasts in some of the pages
        const partnerName = window.location.pathname.split('/')?.[1];
        if (partnerName) {
          window.location.href = `/${partnerName}/timeout`;
        }
      },
    };
    return navigation;
  }, [activeScreen, generateBackToPartnerUrl, track, isCapOnePortalEnabled, isNewPaymentFlowEnabled]);
};
