import { Theme } from '@melio/platform-provider';

export const citizensTheme: Theme = {
  logos: {
    light: 'https://platform-static.meliopayments.com/assets/fiserv/FiservLogo.svg',
    dark: 'https://platform-static.meliopayments.com/assets/fiserv/FiservLogoDark.svg',
  },
  fonts: {
    primary: 'Roboto,Arial,sans-serif',
  },
  colors: {
    global: {
      brand: {
        100: '#FFD6C7',
        200: '#FFA785',
        300: '#FF7E4D',
        400: '#FF4E0A',
        500: '#EB4100',
        600: '#CC3900',
        700: '#AD3000',
        800: '#852500',
        900: '#561800',
        1000: '#471400',
      },
      brandSecondary: {
        100: '#FFD6C7',
        200: '#FFA785',
        300: '#FF7E4D',
        400: '#FF4E0A',
        500: '#EB4100',
        600: '#CC3900',
        700: '#AD3000',
        800: '#852500',
        900: '#561800',
        1000: '#471400',
      },
      warning: {
        100: '#FAEFD0',
        200: '#F7E6B5',
        300: '#F3DA91',
        400: '#EFCE6C',
        500: '#F8C84F',
        600: '#F8CA54',
        700: '#F5B30B',
        800: '#E6A809',
        900: '#C99308',
        1000: '#AB7D07',
      },
      success: {
        100: '#BFF3E0',
        200: '#A1EDD1',
        300: '#7EE7C1',
        400: '#46DDA6',
        500: '#24C289',
        600: '#009962',
        700: '#008555',
        800: '#006641',
        900: '#004D31',
        1000: '#003824',
      },
      informative: {
        100: '#DEEDFE',
        200: '#C4DFFD',
        300: '#ABD2FC',
        400: '#8EC2FB',
        500: '#66ADFA',
        600: '#0563D1',
        700: '#044DA2',
        800: '#03428B',
        900: '#023269',
        1000: '#011F41',
      },
      critical: {
        100: '#FDDEDE',
        200: '#FBBCBC',
        300: '#F99F9F',
        400: '#F78282',
        500: '#F44D4D',
        600: '#BC2A2A',
        700: '#9A2222',
        800: '#791B1B',
        900: '#791B1B',
        1000: '#641616',
      },
    },
    semantic: {
      link: {
        primary: {
          rest: '{global.brand.700}',
          hover: '{global.brand.800}',
          pressed: '{global.brand.900}',
        },
      },
    },
    component: {
      pill: {
        secondaryCritical: {
          rest: {
            label: '{global.neutral.1000}',
          },
        },
        secondarySuccess: {
          rest: {
            label: '{global.neutral.1000}',
          },
        },
        secondaryInformative: {
          rest: {
            label: '{global.neutral.1000}',
          },
        },
      },
    },
  },
  icons: {
    bank: 'https://platform-static.meliopayments.com/assets/citizens/Bank.svg',
  },
};
